import { Container, Row } from "reactstrap";
import HeaderComponent from "../Components/Header/HeaderComponent";
import HeaderSection from "../Components/Header/HeaderSection";
import ProductCards from "../Components/ProductCards/index";
import HowSiteWork from "../Components/HowSiteWork/index";
// import SocialMedia from "../Components/SocialMedia/index";
import Footer from "../Components/Footer";
import { useSelector } from "react-redux";

function HomePage() {
  const color = useSelector((state) => state.changeColor);

  return (
    <Container style={color == 'white' ? {border:'1px solid #ccc'} : {border:'none'}} className="shadow bg-body-tertiary">
      <Row>
        <HeaderComponent />
      </Row>
      <Row>
        <HeaderSection />
      </Row>
      <Row>
        <ProductCards />
      </Row>
      <Row>
        <HowSiteWork />
      </Row>
      <Row>
        <Container className="">
          <Footer/>
        </Container>
      </Row>
    </Container>
  );
}

export default HomePage;
