import "./style.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function ProductCards() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const background = useSelector((state) => state.changeBackground);
  
  return (
    <div style={{ background }} className="ProductCards">
      {/* Card1 */}
      <div className="ProductCard_wrap ProductCard_wrap1">
        <div className="ProductCard_smallWrap">
          <h2>{t("portfolio")}</h2>
          <p>{t("pages")}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            fill="currentColor"
            className="ProductCard_image bi bi-arrow-right-circle-fill"
            viewBox="0 0 16 16"
            onClick={() => {
              navigate("/products", { state: "portfolio" });
            }}
          >
            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
          </svg>
        </div>
      </div>
      {/* Card2 */}
      <div className="ProductCard_wrap ProductCard_wrap2">
        <div className="ProductCard_smallWrap">
          <h2>{t("company")}</h2>
          <p>{t("pages")}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            fill="currentColor"
            className="ProductCard_image bi bi-arrow-right-circle-fill"
            viewBox="0 0 16 16"
            onClick={() => {
              navigate("/products", { state: "company" });
            }}
          >
            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
          </svg>
        </div>
      </div>
      {/* Card3 */}
      <div className="ProductCard_wrap ProductCard_wrap3">
        <div className="ProductCard_smallWrap">
          <h2>{t("restuarant")}</h2>
          <p>{t("pages")}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            fill="currentColor"
            className="ProductCard_image bi bi-arrow-right-circle-fill"
            viewBox="0 0 16 16"
            onClick={() => {
              navigate("/products", { state: "restaurant" });
            }}
          >
            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
          </svg>
        </div>
      </div>
      {/* Card4*/}
      <div className="ProductCard_wrap ProductCard_wrap4">
        <div className="ProductCard_smallWrap">
          <h2>{t("blog")}</h2>
          <p>{t("pages")}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            fill="currentColor"
            className="ProductCard_image bi bi-arrow-right-circle-fill"
            viewBox="0 0 16 16"
            onClick={() => {
              navigate("/products", { state: "blog" });
            }}
          >
            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
          </svg>{" "}
        </div>
      </div>
    </div>
  );
}

export default ProductCards;
