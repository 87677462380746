import { Container, Row } from "reactstrap";
import ProductDetails from "../Components/Product/ProductDetails";
import HeaderComponent from "../Components/Header/HeaderComponent";
import Footer from "../Components/Footer";
import { useSelector } from "react-redux";


function ProductDetailPage() {
    const background = useSelector((state) => state.changeBackground);

    return (
        <Container className="shadow  bg-body-tertiary" style={{background}}>
            <Row className="" style={{background}}>
                <HeaderComponent/>
            </Row>
            <Row className="pt-5" style={{background}}>
                <ProductDetails />
            </Row>
            <Row>
                <Footer />
            </Row>
        </Container>

    )

}
export default ProductDetailPage;