// bu sahifada firebase bilan boglanishimizga yordamchi bo'ladigan Keylar berilgan
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyC2PGsjCpH3R__mnD9Ps-ZZOLwnqEGGa7U",
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
 
};

const app = initializeApp(firebaseConfig);
export default app;

