const translationEn = {
    // HOMEPAGE 
    search:"Search...",
    // Header 
    all_website:"All website",
    portfolio_pages:"Portfolio pages",
    company_pages:"Company pages",
    blog_pages:"Blog pages",
    website_elements:"Website elements",
    restuarant_pages:"Restaurant pages",
    web_shopping:"Web shopping",
    all_together:"All together towards a digital life",
    // Cards 
    portfolio:"Portfolio",
    company:"Company",
    restuarant:"Restuarant",
    blog:"Blog",
    pages:"Pages",
    // How it work 
    it_work:"How it work",
    happy_clients:"Happy clients",
    website:"Website",
    year:"Year",
    // Footer 
    future_ready:"Be Future Ready",
    ready_text:"It publishing and graphic design, is placeholder.",
    about:"About",
    resources:"Resources",
    contact:"Contact us",
    career:"Career",
    awards:"Awards",
    our_team:"Our Team",
    press:"Press",
    newsletter:"Newsletter",
    recipient:"Recipient's username",

    // MY ACCOUNT 
    my_profile:"My Profile",
    my_favouritres:"My Favouritres",
    settings:"Settings",
    key:"Key",
    value:"Value",
    fullname:"Fullname",
    username:"Username",
    email:"Email",
    password:"Password",
    sign_out:"Sign out",
    delete_account:"Delete account",
    learn_more:"Learn more",
    remove:"Remove",
    general_product:"General order",
    tovar_narxi:"commodity price",
    general:"General",

    //PRODUCT DETAIL
    see_website:"See website",
    add_cart:"Add to cart",
    buy_now:"Buy now",
    cart:"Cart"
}

export default translationEn;