import React from 'react';
import { useTranslation } from "react-i18next";

function HeaderSection() {
	const { t, i18n } = useTranslation();

	return (
		<div className='header-section'>
			<div className="card">
				<h1>{t('web_shopping')}</h1>
				<p>{t('all_together')}</p>
			</div>
		</div>
	)
}

export default HeaderSection