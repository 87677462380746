import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { id_product } from "../../../Redux/actions/actions";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './style.css';
import { useTranslation } from "react-i18next";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import app from "../../../Backgrounds/firebase";

function ProductCard({ product }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [likeData, setLikeData] = useState([]);
  const uid = useSelector((state) => state.userIdReducer.uid);
  const [someLike, setSomeLike] = useState(
    likeData ? likeData.some((el) => product.id == el.id) : false
  );
  const color = useSelector((state) => state.changeColor);
  const background = useSelector((state) => state.changeBackground);

  // TAOSTIFY
  const notifyError = () => {
    toast.error("Removed From Saved !", {
      position: "top-center",
      autoClose: 1500,
    });
  };

  const notifySuccess = () => {
    toast.success("Add To Saved !", {
      position: "top-center",
      autoClose: 1500,
    });
  };

  useEffect(() => {
    getLikeBtn();
  }, []);

  const db = getFirestore(app);

  async function getLikeBtn() {
    const updatedProducts = [...likeData];
    const coll = collection(db, `/LikeProduct/${uid}/${uid}`);
    const snapshot = await getDocs(coll);
    // for operatorumiz fireStoredagi barcha ma'lumotlarni o'qiy olishi uchun ishlash sonini belgilarni soniga ko'ra togi'rladik

    for (let index = 1; index <= snapshot.size; index++) {
      const docRef = doc(
        db,
        `/LikeProduct/${uid}/${uid}`,
        `${snapshot.docs[index - 1].id}`
      );
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        updatedProducts.push(docSnap.data());
        setLikeData([...updatedProducts]);
        setSomeLike([...updatedProducts].some((el) => product.id == el.id));
      } else {
        console.log("No such document!");
      }
    }
  }

  const changeId = () => {
    dispatch(id_product(product.id));
    window.location.href = "/product/detail";
    console.log(product.id);
  };

  async function RemovedClick() {
    notifyError();
    const deletedProduct = [...likeData];
    await deleteDoc(doc(db, `/LikeProduct/${uid}/${uid}`, `${product.id}`));
    let deletedProducts = deletedProduct?.filter((el) => el.id != product.id);
    setLikeData([...deletedProducts]);
    setSomeLike([...deletedProducts].some((el) => product.id == el.id));
    console.log("o'chirildi");
  }
  async function AddClick() {
    notifySuccess();
    // Set with Like
    const saveLike = [...likeData];

    const ref = doc(db, `/LikeProduct/${uid}/${uid}`, `${product.id}`);
    await setDoc(ref, product);
    saveLike.push(product);
    setLikeData([...saveLike]);
    setSomeLike([...saveLike].some((el) => product.id == el.id));
    console.log("bu saqlandi");
  }

  const likeBtn = async () => {
    await getLikeBtn();
    if (someLike) {
      RemovedClick();
    } else {
      AddClick();
    }
  };

  return (
    <div className="card mt-3" style={{border:`1px solid ${color == 'white' ? "#ccc" : color}`, width: "18rem", height: "27rem", background }}>
      <img
        height={170}
        src={product?.image}
        style={{ objectFit: "cover", borderBottom:`1px solid ${color == 'white' ? "#ccc" : color}` }}
        className="card-img-top"
        alt="card image"
      />
      <div className="card-body">
        <div>
          <h3 className="card-title text-center" style={{ color }}>
            {product?.title}
          </h3>
          <h5 className="card-title  text-center text-warning">
            {product?.price}$
          </h5>
          <p className="card-text  text-center" style={{ color }}>
            {product?.description
              ? product?.description
              : "Some quick example text to"}
          </p>
        </div>
        <a
          href="#"
          className="btn w-100 btn-warning text-light"
          onClick={changeId}
        >
          {t("learn_more")}
        </a>
      </div>
      <svg
        onClick={() => {
          likeBtn();
        }}
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        fill={someLike ? "red" : "green"}
        className="bi bi-heart-fill"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"
        />
      </svg>
      {!someLike && <ToastContainer />}
    </div>
  );
}

export default ProductCard;
