const translationUz = {
    // HOMEPAGE 
    search:"Qidirish...",
    // Header 
    all_website:"Barcha veb-sayt",
    portfolio_pages:"Portfel sahifalari",
    company_pages:"Kompaniya sahifalari",
    restuarant_pages:"Restoran sahifalari", 
    blog_pages:"Blog sahifalari",
    website_elements:"Veb-sayt elementlari",
    web_shopping:"Web shopping",
    all_together:"Hammasi birgalikda raqamli hayot sari",
    // Cards 
    portfolio:"Portfel",
    company:"Kompaniya",
    restuarant:"Restoran",
    blog:"Blog",
    pages:"Sahifalar",
    // How it work 
    it_work:"Qanday ishlaydi",
    happy_clients:"Mamnun mijozlar",
    website:"Veb-site",
    year:"Yil",
    // Footer 
    future_ready:"Kelajakka tayyor bo'ling",
    ready_text:"Bu nashriyot va grafik dizayn, to'ldiruvchi hisoblanadi.",
    about:"Haqida",
    resources:"Resurslar",
    contact:"Biz bilan bog'lanish",
    career:"Karyera",
    awards:"Mukofotlar",
    our_team:"Bizning Jamoamiz",
    press:"Bosing",
    newsletter:"Axborot byulleteni",
    recipient:"Qabul qiluvchining foydalanuvchi nomi",
    
    // MY ACCOUNT 
    my_profile:"Mening profilim",
    my_favouritres:"Mening sevimlilarim",
    settings:"Sozlamalar",
    key:"Kalit",
    value:"Qiymat",
    fullname:"To'liq ism",
    username:"Foydalanuvchi nomi",
    email:"Elektron pochta",
    password:"Parol",
    sign_out:"Tizimdan chiqish",
    delete_account:"Hisobni o'chirish",
    remove:"Olib tashlash",
    learn_more:"Batafsil ma'lumot",
    general_product:"Umumiy buyurtma",
    tovar_narxi:"Umumiy buyurtma",
    general:"Umumiy",
    
    //PRODUCT DETAIL
    see_website:"Veb-saytni ko'rish",
    add_cart:"Savatga qo'shing",
    buy_now:"Hozir sotib oling",
    cart:"Savat"
}

export default translationUz;