import * as actionType from "../../actions/action_types"
import initialStates from "../initialStates";


export default function bg_toggle_reducer(state=initialStates.toggle_btn_bg.value ,action){
    switch (action.type) {
        case actionType.TOGGLE_BUTTON_BACKGROUND:
            return (action.payload)
         
       
        default:
            return state ;
    }
}
