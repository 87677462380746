import { useEffect, useState } from "react";
import CardFavouritesImage from "../../../Images/company.jpg";
import { useTranslation } from "react-i18next";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { id_product } from "../../../Redux/actions/actions";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  deleteDoc,
} from "firebase/firestore";
import app from "../../../Backgrounds/firebase";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function MyFavourites() {
  const { t } = useTranslation();
  const background = useSelector((state) => state.changeBackground);
  const color = useSelector((state) => state.changeColor);
  const [likeData, setLikeData] = useState([]);
  const uid = useSelector(state => state.userIdReducer.uid);
  const dispatch = useDispatch();

  // TAOSTIFY ERROR
  const notifyError = () => {
    toast.error("Removed From Saved !", {
      position: "top-center",
      autoClose: 2000,
    });
  };

  const changeId = (id) => {
    dispatch(id_product(id));
    window.location.href = "/product/detail";
    console.log(id);
  };

  const db = getFirestore(app);

  async function getLikeBtn() {
    const updatedProducts = [...likeData];

    const coll = collection(
      db,
      `/LikeProduct/${uid}/${uid}`
    );
    const snapshot = await getDocs(coll);
    // for operatorumiz fireStoredagi barcha ma'lumotlarni o'qiy olishi uchun ishlash sonini belgilarni soniga ko'ra togi'rladik

    for (let index = 1; index <= snapshot.size; index++) {
      const docRef = doc(
        db,
        `/LikeProduct/${uid}/${uid}`,
        `${snapshot.docs[index - 1].id}`
      );
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log(docSnap.data());
        updatedProducts.push(docSnap.data());
        setLikeData([...updatedProducts]);
      } else {
        console.log("No such document!");
      }
    }
  }

  async function heartClickDelete(id) {
    notifyError();
    const deletedProduct = [...likeData];
    await deleteDoc(
      doc(db, `/LikeProduct/${uid}/${uid}`, `${id}`)
    );
    let deletedProducts = deletedProduct?.filter((el) => el.id != id);
    setLikeData([...deletedProducts]);
  }

  useEffect(() => {
    getLikeBtn();
  }, []);

  return (
    <div
      className="favourites d-flex justify-content-between flex-wrap"
      style={
        likeData.length == 0 ? { background, height: "100vh" } : { background }
      }
    >
      {likeData &&
        likeData?.map((el, index) => (
          <div
            className="card mt-3"
            style={{ width: "18rem", height: "27rem" }}
            key={index}
          >
            <img
              height={170}
              src={el?.image}
              style={{ objectFit: "cover" }}
              className="card-img-top"
              alt="card image"
            />
            <div className="card-body" style={{ background }}>
              <div>
                <h3 className="card-title text-center" style={{ color }}>
                  {el?.title}
                </h3>
                <h5 className="card-title  text-center text-warning">
                  {el?.price}$
                </h5>
                <p className="card-text  text-center" style={{ color }}>
                  {el?.description
                    ? el?.description
                    : "Some quick example text to"}
                </p>
              </div>
              <a href="#" className="btn w-100 btn-warning text-light" onClick={() => { changeId(el?.id) }}>
                {t("learn_more")}
              </a>
            </div>
            <svg
              onClick={() => {
                heartClickDelete(el.id);
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="red"
              className="bi bi-heart-fill"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"
              />
            </svg>
          </div>
        ))}
      {<ToastContainer />}
    </div>
  );
}

export default MyFavourites;
