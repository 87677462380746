import * as actionType from "../../actions/action_types"
import initialStates from "../initialStates";


export default function searchValue(state=initialStates.search_value, action){
    switch (action.type) {
        case actionType.SEARCH_VALUE:
            return (action.payload)
         
       
        default:
            return state ;
    }
}
