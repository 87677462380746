const translationRu = {
    // HOMEPAGE 
    search:"Поиск...",
    // Header 
    all_website:"Весь сайт",
    portfolio_pages:"Страницы портфолио",
    company_pages:"Страницы компании",
    blog_pages:"Страницы блога",
    website_elements:"Элементы веб-сайта",
    restuarant_pages:"Страницы ресторанов", 
    web_shopping:"Веб  Шопинг",
    all_together:"Все вместе к цифровой жизни",
    // Cards 
    portfolio:"портфолио",
    company:"Компания",
    restuarant:"Ресторан",
    blog:"Блог",
    pages:"Страницы",
    // How it work 
    it_work:"Как это работает",
    happy_clients:"Довольные клиенты",
    website:"Веб-сайт",
    year:"Год",
    // Footer 
    future_ready:"Будьте готовы к будущему",
    ready_text:"Это издательское дело и графический дизайн, это заглушка.",
    about:"О",
    resources:"Ресурсы",
    contact:"Связаться с нами",
    career:"Карьера",
    awards:"Награды",
    our_team:"Наша команда",
    press:"Нажимать",
    newsletter:"Новостная рассылка",
    recipient:"Имя пользователя получателя",
    
    // MY ACCOUNT 
    my_profile:"Мой профайл",
    my_favouritres:"Мои Избранные",
    settings:"Настройки",
    key:"Ключ",
    value:"Ценить",
    fullname:"Полное имя",
    username:"Имя пользователя",
    email:"Электронная почта",
    password:"Пароль",
    sign_out:"Выход",
    delete_account:"Удалить аккаунт",
    learn_more:"Узнать больше",
    remove:"Cнять",
    general_product:"Общий порядок",
    tovar_narxi:"цена товара",
    general:"Общий",
    
    //PRODUCT DETAIL
    see_website:"Посмотреть веб-сайт",
    add_cart:"Добавить в корзину",
    buy_now:"Купить сейчас",
    cart:"Корзину"

}

export default translationRu;