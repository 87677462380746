import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css'
import { useTranslation } from 'react-i18next';

function HowSiteWork() {
    const { t, i18n } = useTranslation();

  return (
    <div className='HowSiteWork w-100 p-4 text-light'>
        <div className="HowSiteWork_wrap pb-4">
            <h2 className='text-center mb-5 pt-2 pb-2'>{t('it_work')}</h2>
            <div className=" d-flex justify-content-around align-center flex-wrap gap-4">
                <div className="HowSiteWork--about">
                    <h2>620+</h2>
                    <p>{t('happy_clients')}</p>
                </div>
                <div className="HowSiteWork--about">
                    <h2>100+</h2>
                    <p>{t('website')}</p>
                </div>
                <div className="HowSiteWork--about">
                    <h2>10+</h2>
                    <p>{t('awards')}</p>
                </div>
                <div className="HowSiteWork--about">
                    <h2>1+</h2>
                    <p>{t('year')}</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HowSiteWork;