import { combineReducers } from "redux";
import changeBackground from "./allReducers/changeBackgroud";
import changeColor from "./allReducers/changeColor";
import signUpErrorReducer from "./allReducers/signUpError";
import userEmailReducer from "./allReducers/userEmailReducer";
import userFullnameReducer from "./allReducers/userFullnamereducer";
import userPasswordReducer from "./allReducers/userPasswordReducer";
import uNameReducer from "./allReducers/uNamereducer";
import productIdReducer from "./allReducers/productIdReducer";
import changeLanguage from './allReducers/changeLanguage'
import bg_toggle_reducer from "./allReducers/BgToggleReducer";
import userIdReducer from "./allReducers/userIdReducer";
import cartChange from "./allReducers/CartChange"
import allProductsSearch from "./allReducers/allProductsSearch";
import searchValue from "./allReducers/searchValue";

const rootReducer = combineReducers({
     changeLanguage,
     changeBackground,
     changeColor,
     signUpErrorReducer,
     userEmailReducer,
     userFullnameReducer,
     userPasswordReducer,
     uNameReducer,
     userIdReducer,
     productIdReducer,
     bg_toggle_reducer,
     cartChange,
     allProductsSearch,
     searchValue
})

export default rootReducer;