import * as actionType from '../../actions/action_types'
import initialStates from '../initialStates';

export default function productIdReducer(state=initialStates.productId.id, action) {
 switch (action.type) {
        case actionType.PRODUCT_ID_TO_DETAIL:
            return {
                ...state , id: action.payload
            }
         
        default:
            return state ;
    }
}