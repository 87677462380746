import "./index.css";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import app from "../../../Backgrounds/firebase";
import {
  user_email,
  user_fullname,
  user_password,
  username_action,
} from "../../../Redux/actions/actions";

function Settings() {
  const { t } = useTranslation();
  const background = useSelector((state) => state.changeBackground);
  const color = useSelector((state) => state.changeColor);
  const fullName = useSelector((state) => state.userFullnameReducer.fullname);
  const username = useSelector((state) => state.uNameReducer?.uname);
  const email = useSelector((state) => state.userEmailReducer.email);
  const password = useSelector((state) => state.userPasswordReducer.password);
  const uid = useSelector(state => state.userIdReducer.uid)
  const db = getFirestore(app);
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [userIndex, setUserIndex] = useState("fullName");
  const updateRef = useRef();

  const getUser = async () => {
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setUser(docSnap.data());
      dispatch(user_fullname(docSnap.data().fullName));
      dispatch(username_action(docSnap.data().username));
      dispatch(user_email(docSnap.data().email));
      dispatch(user_password(docSnap.data().password));
    } else {
      console.log("No such document!");
    }
  };

  useEffect(() => {
    uid && getUser();
  }, []);

  function handleIndex(userI) {
    setUserIndex(userI);
  }

  async function handleSend() {
    const update = doc(db, "users", uid);

    (userIndex == "fullName" &&
      (await updateDoc(update, {
        fullName: updateRef.current.value,
      }))) ||
      (userIndex == "username" &&
        (await updateDoc(update, {
          username: updateRef.current.value,
        }))) ||
      (userIndex == "email" &&
        (await updateDoc(update, {
          email: updateRef.current.value,
        }))) ||
      (userIndex == "password" &&
        (await updateDoc(update, {
          password: updateRef.current.value,
        })));

      (userIndex == "fullName" &&  dispatch(user_fullname(updateRef.current.value))) ||
      (userIndex == "username" && dispatch(username_action(updateRef.current.value))) ||
      (userIndex == "email" && dispatch(user_email(updateRef.current.value))) ||
      (userIndex == "password" && dispatch(user_password(updateRef.current.value)));
  }

  return (
    <div className="settings" style={{ background }}>
      <div className="settings_information">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="60"
          height="60"
          fill={color}
          className="bi bi-person-circle"
          viewBox="0 0 16 16"
        >
          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
          <path
            fillRule="evenodd"
            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
          />
        </svg>
        <div className="settings_information_text">
          <h3 style={{ color }}>
            {user ? user?.fullName : "Xoldarov Azizbek"}
          </h3>
          <span style={{ color }}>
            {" "}
            {user ? user?.email : "azizbek@gmail.com"}
          </span>
        </div>
      </div>
      <div className="settings_message_all">
        <div className="setting_wrap">
          <label htmlFor="name" style={{ color }}>
            {t("fullname")}:
          </label>
          <div
            style={
              color == "white"
                ? { border: "1px solid #ccc" }
                : { border: "1px solid #000" }
            }
            id="name"
          >
            <h5 style={{ color }}>{user ? fullName : "FullName"}</h5>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="green"
              className="bi bi-pen-fill"
              color="green"
              viewBox="0 0 16 16"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={() => {
                handleIndex("fullName");
              }}
            >
              <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001" />
            </svg>
          </div>
        </div>
        <div className="setting_wrap">
          <label htmlFor="name" style={{ color }}>
            {t("username")}:
          </label>
          <div
            style={
              color == "white"
                ? { border: "1px solid #ccc" }
                : { border: "1px solid #000" }
            }
            id="name"
          >
            <h5 style={{ color }}>{user ? username : "username"}</h5>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-pen-fill"
              color="green"
              viewBox="0 0 16 16"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={() => {
                handleIndex("username");
              }}
            >
              <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001" />
            </svg>
          </div>
        </div>
        <div className="setting_wrap">
          <label htmlFor="name" style={{ color }}>
            {t("email")}:
          </label>
          <div
            style={
              color == "white"
                ? { border: "1px solid #ccc" }
                : { border: "1px solid #000" }
            }
            id="name"
          >
            <h5 style={{ color }}>{user ? email : "text@gmail.com"}</h5>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-pen-fill"
              color="green"
              viewBox="0 0 16 16"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={() => {
                handleIndex("email");
              }}
            >
              <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001" />
            </svg>
          </div>
        </div>
        <div className="setting_wrap">
          <label htmlFor="name" style={{ color }}>
            {t("password")}:
          </label>
          <div
            style={
              color == "white"
                ? { border: "1px solid #ccc" }
                : { border: "1px solid #000" }
            }
            id="name"
          >
            <h5 style={{ color }}>{"••••••"}</h5>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-pen-fill"
              color="green"
              viewBox="0 0 16 16"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={() => {
                handleIndex("password");
              }}
            >
              <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001" />
            </svg>
          </div>
        </div>
        <div className="setting_wrap">
          <label htmlFor="name" style={{ color }}>
            {t("password")}:
          </label>
          <div
            style={
              color == "white"
                ? { border: "1px solid #ccc" }
                : { border: "1px solid #000" }
            }
            id="name"
          >
            <h5 style={{ color }}>{"••••••"}</h5>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-pen-fill"
              color="green"
              viewBox="0 0 16 16"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={() => {
                handleIndex("password");
              }}
            >
              <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001" />
            </svg>
          </div>
        </div>
      </div>

      {/* MODAL */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Update message
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    {(userIndex == "fullName" && "FullName") ||
                      (userIndex == "username" && "Username") ||
                      (userIndex == "email" && "Email") ||
                      (userIndex == "password" && "Password") ||
                      ""}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="recipient-name"
                    ref={updateRef}
                    defaultValue={
                      (userIndex == "fullName" && fullName) ||
                      (userIndex == "username" && username) ||
                      (userIndex == "email" && email) ||
                      (userIndex == "password" && password) ||
                      ""
                    }
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSend}
                data-bs-dismiss="modal"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
