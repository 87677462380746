const translationTr = {
    // HOMEPAGE 
    search:"Aramak...",
    // Header 
    all_website:"Tüm web sitesi",
    portfolio_pages:"Portföy sayfaları",
    company_pages:"Şirket sayfaları",
    restuarant_pages:"Restoran sayfaları",
    blog_pages:"Blog sayfaları",
    website_elements:"Web sitesi öğeleri",
    web_shopping:"Web shopping",
    all_together:"Hep birlikte dijital hayata doğru",
    // Cards 
    portfolio:"Portföy",
    company:"Şirket",
    restuarant:"Restoran",
    blog:"Blog",
    pages:"Sayfalar",
    // How it work 
    it_work:"Nasıl çalışır",
    happy_clients:"Mutlu müşteriler",
    website:"İnternet sitesi",
    year:"Yıl",
    // Footer 
    future_ready:"Geleceğe Hazır Olun",
    ready_text:"Yayıncılık ve grafik tasarım, yer tutucudur.",
    about:"Hakkında",
    resources:"Kaynaklar",
    contact:"Bize Ulaşın",
    career:"Kariyer",
    awards:"Ödüller",
    our_team:"Bizim takım",
    press:"Basmak",
    newsletter:"Haber Bülteni",
    recipient:"Alıcının kullanıcı adı",
    
    // MY ACCOUNT 
    my_profile:"Benim profilim",
    my_favouritres:"Favorilerim",
    settings:"Ayarlar",
    key:"Anahtar",
    value:"Değer",
    fullname:"Ad Soyad",
    username:"Kullanıcı adı",
    email:"E-posta",
    password:"Şifre",
    sign_out:"Oturumu Kapat",
    delete_account:"Hesabı sil",
    learn_more:"Bilgi",
    remove:"Devirmek",
    general_product:"Genel düzen",
    tovar_narxi:"Ürün fiyatı",
    general:"Genel",
    
    //PRODUCT DETAIL
    see_website:"Web sitesine bakın",
    add_cart:"Sepete ekle",
    buy_now:"Şimdi al",
    cart:"Sepet"
}

export default translationTr;