import * as actionType from '../../actions/action_types'
import initialStates from '../initialStates';

function changeLanguage(state=initialStates.default_language.lang, action) {
 switch (action.type) {
        case actionType.CHANGE_LANGUAGE_TO_UZB:
            return (action.payload)
         
        case actionType.CHANGE_LANGUAGE_TO_RUS:
             return (action.payload)
         
        case actionType.CHANGE_LANGUAGE_TO_TURK:
             return (action.payload)
         
        case actionType.CHANGE_LANGUAGE_TO_ENG:
             return (action.payload)

        default:
            return state ;
    }
}

export default changeLanguage;