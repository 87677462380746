
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  deleteUser,
  updateEmail,
} from "firebase/auth";
import app from "./firebase";
import {
  doc,
  getDoc,
  getFirestore,
  setDoc,
} from "firebase/firestore";

const auth = getAuth(app);
const user = auth.currentUser;
const db = getFirestore(app);

export async function signUp(email, password, username, fullname) {
  try {
    await createUserWithEmailAndPassword(auth, email, password).then(
      async (userCredential) => {
        await setDoc(doc(db, "users", userCredential.user.uid), {
          fullName: fullname,
          email: email,
          username: username,
          password: password,
        }).then(() => {
          console.log("Obuna bo'lindi");
        });
      }
    );
    alert("Muavfaqiyatli bir shaklda obuna bo'lindi");
  } catch (error) {
    alert(error.message);
  }
}

export async function getUserFirebase() {
  const docRef = doc(db, "users", "rDY6YDzwCXS9a9TboSMiilJhNBD3");
  const docSnap = await getDoc(docRef);

  try {
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log("No such document!");
    }
  } catch (err) {
    console.log("No such document!", err);
  }
}

export function sign_out() {
  signOut(auth)
    .then(() => {
      console.log("Sign-out successful.");
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function delete_User(email, password) {
  await signInWithEmailAndPassword(auth, email, password);
  await deleteUser(user)
    .then(() => {
      console.log("silindi");
    })
    .catch((error) => {
      console.log(error.message);
    });
}

export function update_Email(email) {
  updateEmail(auth.currentUser, email)
    .then(() => {
      // Email updated!
      // ...
    })
    .catch((error) => {
      // An error occurred
      // ...
    });
}
