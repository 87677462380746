import * as actionType from "../../actions/action_types"
import initialStates from "../initialStates";

export default function changeColor(state=initialStates.current_color.color, action){
    switch (action.type) {
        case actionType.CHANGE_COLOR_TO_WHITE:
            return (action.payload)
         
        case actionType.CHANGE_COLOR_TO_DARK:
             return (action.payload)

        default:
            return state ;
    }
}