import { Container, Row } from "reactstrap";
import HeaderComponent from "../Components/Header/HeaderComponent";
import ProductList from "../Components/Product/ProductList";
import HeaderSection from "../Components/Header/HeaderSection";
import Footer from "../Components/Footer";

function ProductListPage() {

    return (
        <Container className="shadow  bg-body-tertiary">
            <Row>
                <HeaderComponent/>
            </Row>
            <Row>
                <HeaderSection/>
            </Row>            
            <Row>
                <ProductList/> 
            </Row>
            <Row>
                <Footer />
            </Row>

        </Container>
    );
}
export default ProductListPage;