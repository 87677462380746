import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from '../Pages/homePage';
import ProductDetailPage from '../Pages/productDetailPage';
import ProductListPage from '../Pages/productsPages';
import MyAccount from '../Pages/myAccountpage';
import { Route, Routes } from "react-router-dom";
import { useSelector } from 'react-redux';
import SignUp from '../Components/Signs/SignUp';
import SignIn from "../Components/Signs/SignIn";
import UploadData from '../Components/UploadDataToFirebase/index';
import "react-loading-skeleton/dist/skeleton.css";
import ForgotPassword from '../Components/Signs/ForgotPs';
import NoPage from '../Components/NoPage/noPage';

function App() {
  const uid = useSelector(state => state.userIdReducer.uid);
  const background = useSelector(state => state.changeBackground);
  const screenControl = useSelector(state => state.userEmailReducer.email)

  if (screenControl == null) {
    
    return (

      <div className="App" style={{ background }} >
        <Routes>
  
          <Route path="/" element={<HomePage />}></Route>

          <Route path='/form' element={<UploadData/>} ></Route>
  
          <Route path="/account" element={<SignIn />}></Route>
  
          <Route path="/products" element={<ProductListPage />}></Route>
  
          <Route path="/product/detail" element={<ProductDetailPage />}></Route>
  
          <Route path="/signup" element={<SignUp />}></Route>
  
          <Route path="/signin" element={<SignIn />}></Route>
          
          <Route path="/forgot" element={<ForgotPassword/>}></Route>

          <Route path="*" element={<NoPage />} />

        </Routes>
      </div>
    );
  
  
  }else{
    return (
      <div className="App" style={{ background }} >
        <Routes>
  
          <Route path="/" element={<HomePage />}></Route>
  
          <Route path='/form' element={<UploadData/>} ></Route>

          <Route path="/account" element={<MyAccount/>}></Route>
  
          <Route path="/products" element={<ProductListPage />}></Route>
  
          <Route path="/product/detail" element={<ProductDetailPage />}></Route>
  
          <Route path="/signup" element={<SignUp />}></Route>
  
          <Route path="/signin" element={<SignIn />}></Route>

          <Route path="/forgot" element={<ForgotPassword/>}></Route>

          <Route path="*" element={<NoPage />} />

        </Routes>
      </div>
    );
  }
  
}

export default App;