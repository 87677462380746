const language = JSON.parse(localStorage.getItem("lang"));

export default {
    current_background: { bgColor: "white" },
    current_color: { color: "#2c2b2b" },
    sign_error_message: { message: "" },
    user_username: { uname: "" },
    user_fullname: { fullname: "" },
    user_email: { email: "null" },
    user_password: { password: "" },
    user_id: { uid: "" },
    productId: { id: "" },
    toggle_btn_bg: { value: true },
    cart_default:0,
    all_products:[],
    search_value:'',
    default_language: {
        lang: language ?
            (language == "uz" && "UZB" ||
                language == "ru" && "RUS" ||
                language == "en" && "ENG" ||
                language == "tr" && "TURK") :
            "ENG"
    }
}