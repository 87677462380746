import * as actionType from "../../actions/action_types"
import initialStates from "../initialStates";


export default function allProductsSearch(state=initialStates.all_products, action){
    switch (action.type) {
        case actionType.ALL_PRODUCTS:
            return (action.payload)
         
       
        default:
            return state ;
    }
}
