import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { delete_User, sign_out } from "../../../Backgrounds/auth";
import { user_fullname, user_email, username_action, user_password, userId } from "../../../Redux/actions/actions";
import app from "../../../Backgrounds/firebase";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useEffect } from "react";

function MyProfile() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const background = useSelector(state => state.changeBackground);
  const color = useSelector(state => state.changeColor);
  const username = useSelector(state => state.uNameReducer.uname);
  const fullname = useSelector(state => state.userFullnameReducer.fullname);
  const email = useSelector(state => state.userEmailReducer.email);
  const password = useSelector(state => state.userPasswordReducer.password);
  const uid = useSelector(state => state.userIdReducer.uid);
  const db = getFirestore(app);
  console.log(uid)
  
  const deleteAcc = async (email, password) => {
    await delete_User(email, password);
    dispatch(userId(null))
    dispatch(user_email(null));
    dispatch(user_fullname(null));
    dispatch(user_password(null));
    dispatch(username_action(null));
    window.location.href = "/signin";
  }

  const signOut = async () => {
    sign_out();
    dispatch(userId(null))
    dispatch(user_email(null));
    dispatch(user_fullname(null));
    dispatch(user_password(null));
    dispatch(username_action(null));
  }

  // GET DATA 
  const getUser = async () => {
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      dispatch(user_fullname(docSnap.data().fullName));
      dispatch(username_action(docSnap.data().username));
      dispatch(user_email(docSnap.data().email));
      dispatch(user_password(docSnap.data().password));
    } else {
      console.log("No such document!");
    }
  };

  useEffect(() => {
    uid && getUser();
  }, []);

  return (
    <div className="profile" style={{ background }}>
      <div className="profile_information">
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill={color} className="bi bi-person-circle" viewBox="0 0 16 16">
          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
          <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1" />
        </svg>
        <div className="profile_information_text">
          <h3 style={{ color }}>{fullname}</h3>
          <span style={{ color }}>{email}</span>
        </div>
      </div>

      <table className="table w-50 table-light">
        <thead>
          <tr>
            <th>{t("key")}</th>
            <th>{t("value")}</th>
          </tr>
        </thead>
        <tbody>
          <tr className="table-active">
            <th>{t("fullname")}</th>
            <td>{fullname}</td>
          </tr>
          <tr>
            <th>{t("username")}</th>
            <td>{username}</td>
          </tr>
          <tr className="table-active">
            <th>{t("email")}</th>
            <td>{email}</td>
          </tr>
          <tr>
            <th>{t("password")}</th>
            <td>######</td>
          </tr>
        </tbody>
      </table>
      <div className="d-grid gap-3 d-md-block">
        <button className="btn btn-primary" onClick={signOut} type="button">
          <span>{t("sign_out")}</span>
          <i className="bi bi-box-arrow-right"></i>
        </button>
        <button className="btn ms-2 btn-danger" type="button" onClick={() => deleteAcc(email, password)}>
          <span>{t("delete_account")}</span>
          <i className="bi bi-person-dash-fill"></i>
        </button>
      </div>
    </div>
  );
}

export default MyProfile;
