import * as actionType from "../../actions/action_types"
import initialStates from "../initialStates";

export default function cartChange(state=initialStates.cart_default, action){
    switch (action.type) {
        case actionType.CHANGE_CART_NUMBER:
            return (action.payload)

        default:
            return state ;
    }
}