import * as actionType from "../../actions/action_types"
import initialStates from "../initialStates";


export default function changeBackground(state=initialStates.current_background.bgColor ,action){
    switch (action.type) {
        case actionType.CHANGE_BACKGROUND_TO_WHITE:
            return (action.payload)
         
        case actionType.CHANGE_BACKGROUND_TO_DARK:
             return (action.payload)

        default:
            return state ;
    }
}

