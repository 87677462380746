 import * as actionTypes from "./action_types"
import initialStates from "../reducers/initialStates"

export function changeBackgroundToDark(){
    return {
        type: actionTypes.CHANGE_BACKGROUND_TO_DARK,
        payload: initialStates.current_color.color
    }
}

export function changeBackgroundToWhite(){
    return {
        type: actionTypes.CHANGE_BACKGROUND_TO_WHITE,
        payload: initialStates.current_background.bgColor
    }
}
export function changeColorToDark(){
    return {
        type: actionTypes.CHANGE_COLOR_TO_DARK,
        payload: "black"
    }
}

export function changeColorToWhite(){
    return {
        type: actionTypes.CHANGE_COLOR_TO_WHITE,
        payload: "white"
    }
}

export function changeLanguageUzbek(){
    return {
        type: actionTypes.CHANGE_LANGUAGE_TO_UZB,
        payload: "uz"
    }
}

export function changeLanguageRussian(){
    return {
        type: actionTypes.CHANGE_LANGUAGE_TO_RUS,
        payload: "ru"
    }
}

export function changeLanguageTurkish(){
    return {
        type: actionTypes.CHANGE_LANGUAGE_TO_TURK,
        payload: "tr"
    }
}

export function changeLanguageEnglish(){
    return {
        type: actionTypes.CHANGE_LANGUAGE_TO_ENG,
        payload: "en"
    }
}

export function signUpError(error){
    return{
        type: actionTypes.SIGN_UP_ERROR_MESSAGE,
        payload : error
    }
}


export function user_fullname(fullName){
    return{
        type: actionTypes.USER_FULLNAME,
        payload : fullName
    }
}

export function user_email(email){
    return{
        type: actionTypes.USER_EMAIL,
        payload : email
    }
}
export function user_password(password){
    return{
        type: actionTypes.USER_PASSWORD,
        payload : password
    }
}

export function username_action(uName){
    return{
        type : actionTypes.USER_USERNAME,
        payload: uName
    }
}

export function id_product(id){
    return{
        type : actionTypes.PRODUCT_ID_TO_DETAIL,
        payload: id
    }
}

export function Bg_toggle_value(value){
    return{
        type : actionTypes.TOGGLE_BUTTON_BACKGROUND,
        payload: value
    }
} 

export function userId(value){
    return{
        type : actionTypes.USER_ID,
        payload: value
    }
} 

export function cartChange(value){
    return{
        type : actionTypes.CHANGE_CART_NUMBER,
        payload: value
    }
} 

export function allProducts(value){
    return{
        type : actionTypes.ALL_PRODUCTS,
        payload: value
    }
} 

export function searchValues(value){
    return{
        type : actionTypes.SEARCH_VALUE,
        payload: value
    }
} 