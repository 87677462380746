import React, { useEffect, useState } from "react";
import ProductCard from "./ProductCard/ProductCard";
import app from "../../Backgrounds/firebase";
import {
  doc,
  getFirestore,
  getDoc,
  getDocs,
  collection,
} from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { allProducts } from "../../Redux/actions/actions";

function ProductList() {
  const [products, setProducts] = useState([]);
  const background = useSelector((state) => state.changeBackground);
  const color = useSelector((state) => state.changeColor);
  const categories = useLocation();
  const dispatch = useDispatch();
  const db = getFirestore(app);
  const filtred = useSelector((state) => state.allProductsSearch);
  const searchValue = useSelector(state => state.searchValue);

  const fetchData = async () => {
    try {
      const updatedProducts = [...products];

      const coll = collection(db, "ProductsDetail");
      const snapshot = await getDocs(coll);
      // for operatorumiz fireStoredagi barcha ma'lumotlarni o'qiy olishi uchun ishlash sonini belgilarni soniga ko'ra togi'rladik

      for (let index = 1; index <= snapshot.size; index++) {
        const docRef = doc(db, "ProductsDetail", `${index}`);

        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          let newProduct = {
            category: docSnap.data().category,
            image: docSnap.data().image,
            id: docSnap.data().id,
            title: docSnap.data().title,
            like: docSnap.data().like,
            price: docSnap.data().price,
            description: docSnap.data().description,
          };

          updatedProducts.push(newProduct);
          setProducts([...updatedProducts]);
          dispatch(allProducts([...updatedProducts]));
        } else {
          console.log("No such document!");
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="product-list" style={{ background, color }}>
      {products[products.length - 1] ? (
        !searchValue ? (
          products.map((product, index) => {
            if (categories?.state == product.category) {
              return <ProductCard key={index} product={product} />;
            }

            if (categories?.state == "all") {
              return <ProductCard key={index} product={product} />;
            }
          })
        ) : (
          filtred.map((product, index) => (
            <ProductCard key={index} product={product} />
          ))
        )
      ) : (
        <h1>Loading...</h1>
      )}
    </div>
  );
}

export default ProductList;
