export const CHANGE_BACKGROUND_TO_DARK = "CHANGE_BACKGROUND_TO_DARK"
export const CHANGE_BACKGROUND_TO_WHITE = "CHANGE_BACKGROUND_TO_WHITE"
export const CHANGE_CART_NUMBER = "CHANGE_CART_NUMBER"
export const CHANGE_COLOR_TO_DARK = "CHANGE_COLOR_TO_DARK"
export const CHANGE_COLOR_TO_WHITE = "CHANGE_COLOR_TO_WHITE"
export const CHANGE_LANGUAGE_TO_UZB = "CHANGE_LANGUAGE_TO_UZB"
export const CHANGE_LANGUAGE_TO_RUS = "CHANGE_LANGUAGE_TO_RUS"
export const CHANGE_LANGUAGE_TO_TURK = "CHANGE_LANGUAGE_TO_TURK"
export const CHANGE_LANGUAGE_TO_ENG = "CHANGE_LANGUAGE_TO_ENG"
export const SIGN_UP_ERROR_MESSAGE = "SIGN_UP_ERROR_MESSAGE"
export const USER_USERNAME = "USER_USERNAME"
export const USER_EMAIL = "USER_EMAIL"
export const USER_PASSWORD = "USER_PASSWORD"
export const USER_FULLNAME = "USER_FULLNAME"
export const USER_ID = "USER_ID"
export const ALL_PRODUCTS = "ALL_PRODUCTS"
export const SEARCH_VALUE = "SEARCH_VALUE"
export const PRODUCT_ID_TO_DETAIL = "PRODUCT_ID_TO_DETAIL"
export const TOGGLE_BUTTON_BACKGROUND = "TOGGLE_BUTTON_BACKGROUND"
