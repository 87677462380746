import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import img from "../../Images/logo5.png";
import imgW from "../../Images/logo6.png";
import * as actionTypes from "../../Redux/actions/action_types";
import {
  changeColorToWhite,
  changeBackgroundToDark,
  changeBackgroundToWhite,
  changeColorToDark,
  searchValues,
  allProducts,
} from "../../Redux/actions/actions";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

function HeaderForAccount() {
  const [active, setActive] = useState("");
  const [isToggled, setIsToggled] = useState(false);
  const background = useSelector((state) => state.changeBackground);
  const cartNumber = useSelector((state) => state.cartChange);
  const [allProduct, setAllData] = useState([]);
  const color = useSelector((state) => state.changeColor);
  const [imgValue, setImgValue] = useState(img);
  const language = useSelector((state) => state.changeLanguage);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const changeLang = (value) => {
    i18n.changeLanguage(value);
  };
  const location = useLocation();
  const navigate = useNavigate();
  const titleRef = useRef();
  const searchValue = useSelector((state) => state.searchValue);

  

  // DROPDOWN CHANGES
  const toggleDropdown = (dropdown) => {
    setActive(active === dropdown ? "" : dropdown);
  };
  const toggleSwitch = () => {
    setIsToggled(!isToggled);
    if (!isToggled) {
      setImgValue(imgW);
      dispatch(changeBackgroundToDark());
      dispatch(changeColorToWhite());
    } else {
      setImgValue(img);
      dispatch(changeBackgroundToWhite());
      dispatch(changeColorToDark());
    }
  };

  // SEARCH
  async function search() {
    dispatch(searchValues(titleRef.current.value));
    const searchTerm = titleRef.current.value.toUpperCase();
    if (searchTerm) {
      const filtered = allProduct.filter((item) =>
        item.title.toUpperCase().includes(searchTerm)
      );
      dispatch(allProducts(filtered));
      navigate("/products");
    } else {
      dispatch(allProducts(allProduct));
      navigate("/products", { state: "all" });
    }
  }

  // Focus on the input field after navigation
  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.focus();
    }
  }, [location]);

  useEffect(() => {
    if (background == "white") {
      setImgValue(img)
    } else {
      setImgValue(imgW);
    }
  })

  // LANGUAGES CHANGES
  function handleUz() {
    changeLang("uz");
    localStorage.setItem("lang", JSON.stringify("uz"));
    dispatch({ type: actionTypes.CHANGE_LANGUAGE_TO_UZB, payload: "UZB" });
    toggleDropdown("select-language");
  }
  function handleRu() {
    changeLang("ru");
    localStorage.setItem("lang", JSON.stringify("ru"));
    dispatch({ type: actionTypes.CHANGE_LANGUAGE_TO_RUS, payload: "RUS" });
    toggleDropdown("select-language");
  }
  function handleEn() {
    changeLang("en");
    localStorage.setItem("lang", JSON.stringify("en"));
    dispatch({ type: actionTypes.CHANGE_LANGUAGE_TO_ENG, payload: "ENG" });
    toggleDropdown("select-language");
  }
  function handleTr() {
    changeLang("tr");
    localStorage.setItem("lang", JSON.stringify("tr"));
    dispatch({ type: actionTypes.CHANGE_LANGUAGE_TO_TURK, payload: "TURK" });
    toggleDropdown("select-language");
  }

  return (
    <div className="HeaderComponent" style={{ background }}>
      <header>
        <div className="search-logo">
          <a href="/">
            <img className="logotip" width={80} src={imgValue} alt="logo" />
          </a>
          <div className="search-input">
            <input
              ref={titleRef}
              type="text"
              defaultValue={searchValue}
              placeholder={t("search")}
              onKeyUp={(e) => {
                search(e);
              }}
            />
            <i className="fa-solid fa-magnifying-glass"></i>
          </div>
        </div>
        <div className="items">
          <div
            className="search-respon-input"
            data-toggle="modal"
            data-target="#exampleModal"
          >
            <i className="fa-solid fa-magnifying-glass"></i>
          </div>
          <div className="account-dark-mode">
            <a href="/account" className="current" style={{ color }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill={color}
                className="bi bi-cart4"
                viewBox="0 0 16 16"
              >
                <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5M3.14 5l.5 2H5V5zM6 5v2h2V5zm3 0v2h2V5zm3 0v2h1.36l.5-2zm1.11 3H12v2h.61zM11 8H9v2h2zM8 8H6v2h2zM5 8H3.89l.5 2H5zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0m9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0" />
              </svg>
              <span className="currentCartNumber">{cartNumber}</span>
            </a>
            <div
              className={`select-language ${active === "select-language" ? "active" : ""
                }`}
            >
              <span className="main-language">
                {language}
                <i
                  onClick={() => toggleDropdown("select-language")}
                  className={`fa-solid ${active === "select-language"
                      ? "bi bi-caret-up-fill"
                      : "fa-chevron-down"
                    } mt-1`}
                ></i>
              </span>
              <div className="dropdown">
                <span onClick={handleUz} className="uzb">
                  Uzbek
                </span>
                <span onClick={handleEn} className="eng">
                  English
                </span>
                <span onClick={handleTr} className="turk">
                  Turkish
                </span>
                <span onClick={handleRu} className="rus">
                  Russian
                </span>
              </div>
            </div>
            <div
              className={`dark-mode ${isToggled ? "toggled" : ""}`}
              onClick={toggleSwitch}
            >
              <div className="toggle-knob">
                {isToggled ? (
                  <i className="fa-solid fa-moon"></i>
                ) : (
                  <i className="fa-solid fa-sun"></i>
                )}
              </div>
            </div>
            <div
              className="account"
              style={
                color == "white"
                  ? { border: "2px solid #fff" }
                  : { border: "2px solid #000" }
              }
            >
              <a href="/account" style={{ color }}>
                <i className="fa-solid fa-user"></i>
              </a>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default HeaderForAccount;
