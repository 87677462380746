import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import app from "../../Backgrounds/firebase";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import { cartChange } from "../../Redux/actions/actions";
import { useNavigate } from "react-router-dom";

function ProductDetails() {
  const background = useSelector((state) => state.changeBackground);
  const color = useSelector((state) => state.changeColor);
  const productId = useSelector((state) => state.productIdReducer.id);
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [oldPrice, setOldPrice] = useState("");
  const [allData, setAllData] = useState("");
  const [newPrice, setnewPrice] = useState("");
  const [likeData, setLikeData] = useState([]);
  const uid = useSelector((state) => state.userIdReducer.uid);
  const [cart, setCart] = useState([]);
  const dispatch = useDispatch();
  const [someLike, setSomeLike] = useState(
    likeData ? likeData.some((el) => id == el.id) : false
  );
  const db = getFirestore(app);

  // TAOSTIFY
  const notifyError = () => {
    toast.error("Removed From Saved !", {
      position: "top-center",
      autoClose: 1500,
    });
  };

  const notifySuccess = () => {
    toast.success("Add To Saved !", {
      position: "top-center",
      autoClose: 1500,
    });
  };

  const notifySuccessToCart = () => {
    toast.success("Add To Cart !", {
      position: "top-center",
      autoClose: 1500,
    });
  };
  const notifySuccessToCarts = () => {
    toast.success("Already Added To Cart !", {
      position: "top-center",
      autoClose: 1500,
    });
  };

  async function getLikeBtn() {
    const updatedProducts = [...likeData];
    const coll = collection(db, `/LikeProduct/${uid}/${uid}`);
    const snapshot = await getDocs(coll);
    // for operatorumiz fireStoredagi barcha ma'lumotlarni o'qiy olishi uchun ishlash sonini belgilarni soniga ko'ra togi'rladik

    for (let index = 1; index <= snapshot.size; index++) {
      const docRef = doc(
        db,
        `/LikeProduct/${uid}/${uid}`,
        `${snapshot.docs[index - 1].id}`
      );
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        updatedProducts.push(docSnap.data());
        setLikeData([...updatedProducts]);
        setSomeLike([...updatedProducts].some((el) => id == el.id));
      } else {
        console.log("No such document!");
      }
    }
  }

  async function getAllCart() {
    const updatedProducts = [...cart];
    const coll = collection(db, `/AddToCart/${uid}/${uid}`);
    const snapshot = await getDocs(coll);
    // for operatorumiz fireStoredagi barcha ma'lumotlarni o'qiy olishi uchun ishlash sonini belgilarni soniga ko'ra togi'rladik

    for (let index = 1; index <= snapshot.size; index++) {
      const docRef = doc(
        db,
        `/AddToCart/${uid}/${uid}`,
        `${snapshot.docs[index - 1].id}`
      );
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        updatedProducts.push(docSnap.data());
        setCart([...updatedProducts]);
        dispatch(cartChange(updatedProducts?.length));
      } else {
        console.log("No such document!");
      }
    }
  }

  const images = [
    {
      src: image,
      alt: "Loading...",
    },
    {
      src: image1,
      alt: "Loading...",
    },
    {
      src: image2,
      alt: "Loading...",
    },
  ];
  const [mainIndex, setMainIndex] = useState(0);

  const handleNext = () => {
    setMainIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setMainIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const fetchData = async () => {
    const docRef = doc(db, "ProductsDetail", `${productId}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setId(docSnap.data().id);
      setImage1(docSnap.data().image1);
      setImage2(docSnap.data().image2);
      setImage(docSnap.data().image);
      setTitle(docSnap.data().title);
      setDescription(docSnap.data().description);
      setLink(docSnap.data()?.webLink);
      setOldPrice(docSnap.data().oldPrice);
      setnewPrice(docSnap.data().price);
      setAllData(docSnap.data());
    } else {
      console.log("No such document!");
    }
  };

  useEffect(() => {
    fetchData();
    getLikeBtn();
    getAllCart();
  }, []);

  const handleAddToCart = async () => {
    if (uid) {
      // Set with Like
      const ref = doc(db, `/AddToCart/${uid}/${uid}`, `${id}`);
      await setDoc(ref, allData);
      console.log("bu saqlandi");
      const copied = [...cart];
      const carts = copied.some((el) => el.id == productId);
      console.log(carts);
      if (!carts) {
        notifySuccessToCart();
        dispatch(cartChange(copied.length + 1));
      } else {
        notifySuccessToCarts();
      }
    } else {
      navigate("/signin");
    }
  };

  async function RemovedClick() {
    notifyError();
    const deletedProduct = [...likeData];
    await deleteDoc(doc(db, `/LikeProduct/${uid}/${uid}`, `${id}`));
    let deletedProducts = deletedProduct?.filter((el) => el.id != id);
    setLikeData([...deletedProducts]);
    setSomeLike([...deletedProducts].some((el) => id == el.id));
    console.log("o'chirildi");
  }

  async function AddClick() {
    notifySuccess();
    // Set with Like
    const saveLike = [...likeData];

    const ref = doc(db, `/LikeProduct/${uid}/${uid}`, `${id}`);
    await setDoc(ref, allData);
    saveLike.push(allData);
    setLikeData([...saveLike]);
    setSomeLike([...saveLike].some((el) => id == el.id));
    console.log("bu saqlandi");
  }

  async function handleBuyNow() {
    if (uid) {
      
    } else {
      navigate("/signin")
    }
  }

  const likeBtn = async () => {
    if (uid) {
      await getLikeBtn();
      if (someLike) {
        RemovedClick();
      } else {
        AddClick();
      }
    } else {
      navigate("/signin")
    }
  };

  return (
    <div className="product-details" style={{ background }}>
      <div className="carousel">
        <div className="main-image-container">
          {images[mainIndex].src ? (
            <img
              src={images[mainIndex].src}
              alt={images[mainIndex].alt}
              className="main-image position-relative"
            />
          ) : (
            <Skeleton height="100%" />
          )}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill={someLike ? "red" : "green"}
            className="bi bi-heart-fill likeIcon"
            viewBox="0 0 16 16"
            onClick={() => {
              likeBtn();
            }}
          >
            <path
              fillRule="evenodd"
              d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"
            />
          </svg>
          <button
            className="arrow left text-primary rounded"
            onClick={handlePrev}
          >
            {"<"}
          </button>
          <button
            className="arrow right text-primary rounded"
            onClick={handleNext}
          >
            {">"}
          </button>
        </div>
        <div className="thumbnails">
          {images.map((image, index) =>
            image.src ? (
              <img
                key={index}
                src={image.src}
                alt={image.alt}
                className={`thumbnail ${index === mainIndex ? "active" : ""}`}
                onClick={() => setMainIndex(index)}
              />
            ) : (
              <Skeleton width="72px" height="40px" />
            )
          )}
        </div>
      </div>
      <div className="about">
        {title ? (
          <h1 className="product-title" style={{ color }}>
            <span className="title-highlight">{title}</span>
          </h1>
        ) : (
          <Skeleton width="100%" style={{ marginBottom: "10px" }} />
        )}
        {description ? (
          <p className="product-description" style={{ color }}>
            {description}
          </p>
        ) : (
          <Skeleton width="100%" style={{ marginBottom: "10px" }} />
        )}
        {oldPrice ? (
          <div className="product-pricing">
            <span className="old-price" style={{ color }}>
              ${oldPrice}
            </span>
            <span className="new-price" style={{ color }}>
              ${newPrice}
            </span>
          </div>
        ) : (
          <Skeleton width="100%" style={{ marginBottom: "40px" }} />
        )}
        <div className="product-buttons">
          <a href={link} className="btn btn-website">{t("see_website")}</a>
          <button onClick={handleAddToCart} className="btn btn-add-to-cart">
            {t("add_cart")}
          </button>
          <button onClick={handleBuyNow}  className="btn btn-buy-now">{t("buy_now")}</button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ProductDetails;
