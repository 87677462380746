import { useEffect, useRef, useState } from "react";
import img from "../../Images/logo5.png";
import imgW from "../../Images/logo6.png";
import { useSelector, useDispatch } from "react-redux";
import {
  changeColorToWhite,
  changeBackgroundToDark,
  changeBackgroundToWhite,
  changeColorToDark,
  allProducts,
  searchValues,
  Bg_toggle_value,
} from "../../Redux/actions/actions";
import { useTranslation } from "react-i18next";
import * as actionTypes from "../../Redux/actions/action_types";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import app from "../../Backgrounds/firebase";

function HeaderComponent() {
  // ALL STATES
  const [active, setActive] = useState("");
  const [allProduct, setAllData] = useState([]);
  const [isToggled, setIsToggled] = useState(
    localStorage.getItem("toggle")
      ? JSON.parse(localStorage.getItem("toggle"))
      : false
  );
  const [isBars, setBars] = useState(true);
  const language = useSelector((state) => state.changeLanguage);
  const db = getFirestore(app);
  const dispatch = useDispatch();
  const cartNumber = useSelector((state) => state.cartChange);
  const background = useSelector((state) => state.changeBackground);
  const color = useSelector((state) => state.changeColor);
  const [imgValue, setImgValue] = useState(color == "black" ? img : imgW);
  const { t, i18n } = useTranslation();
  const changeLang = (value) => {
    i18n.changeLanguage(value);
  };
  const titleRef = useRef();
  const titleResponsiveRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const searchValue = useSelector((state) => state.searchValue);

  // DROPDOWN CHANGES
  const toggleDropdown = (dropdown) => {
    setActive(active === dropdown ? "" : dropdown);
  };
  //Background mode changer
  const toggleSwitch = () => {
    setIsToggled(!isToggled);
    if (!isToggled) {
      setImgValue(imgW);
      // localStorage.setItem('toggle', JSON.stringify(true))
      dispatch(changeBackgroundToDark());
      dispatch(Bg_toggle_value(!isToggled));
      dispatch(changeColorToWhite());
    } else {
      setImgValue(img);
      // localStorage.setItem('toggle', JSON.stringify(false))
      dispatch(Bg_toggle_value(isToggled));
      dispatch(changeBackgroundToWhite());
      dispatch(changeColorToDark());
    }
  };

  async function allData() {
    try {
      const updatedProducts = [];

      const coll = collection(db, "ProductsDetail");
      const snapshot = await getDocs(coll);
      // for operatorumiz fireStoredagi barcha ma'lumotlarni o'qiy olishi uchun ishlash sonini belgilarni soniga ko'ra togi'rladik

      for (let index = 1; index <= snapshot.size; index++) {
        const docRef = doc(db, "ProductsDetail", `${index}`);

        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          let newProduct = {
            category: docSnap.data().category,
            image: docSnap.data().image,
            id: docSnap.data().id,
            title: docSnap.data().title,
            like: docSnap.data().like,
            price: docSnap.data().price,
            description: docSnap.data().description,
          };

          updatedProducts.push(newProduct);
          dispatch(allProducts([...updatedProducts]));
          setAllData([...updatedProducts]);
        } else {
          console.log("No such document!");
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    allData();
    if (background == "white") {
      setImgValue(img);
    } else {
      setImgValue(imgW);
    }
  }, []);

  useEffect(() => {
    if (titleRef.current || titleResponsiveRef.current) {
      titleRef.current.focus();
      titleResponsiveRef.current.focus();
    }
  }, [location]);

  // SEARCH
  async function search() {
    dispatch(searchValues(titleRef.current.value));
    const searchTerm = titleRef.current.value.toUpperCase();
    if (searchTerm) {
      const filtered = allProduct.filter((item) =>
        item.title.toUpperCase().includes(searchTerm)
      );
      dispatch(allProducts(filtered));
      navigate("/products");
    } else {
      dispatch(allProducts(allProduct));
      navigate("/products", { state: "all" });
    }
  }
  // SEARCH RESPONSIVE
  async function searchResponsive() {
    dispatch(searchValues(titleResponsiveRef.current.value));
    const searchTermRes = titleResponsiveRef.current.value.toUpperCase();
    if (searchTermRes) {
      const filtered = allProduct.filter((item) =>
        item.title.toUpperCase().includes(searchTermRes)
      );
      dispatch(allProducts(filtered));
      navigate("/products");
    } else {
      dispatch(allProducts(allProduct));
      navigate("/products", { state: "all" });
    }
  }

  // LANGUAGES CHANGES
  function handleUz() {
    changeLang("uz");
    localStorage.setItem("lang", JSON.stringify("uz"));
    dispatch({ type: actionTypes.CHANGE_LANGUAGE_TO_UZB, payload: "UZB" });
    toggleDropdown("select-language");
  }
  function handleRu() {
    changeLang("ru");
    localStorage.setItem("lang", JSON.stringify("ru"));
    dispatch({ type: actionTypes.CHANGE_LANGUAGE_TO_RUS, payload: "RUS" });
    toggleDropdown("select-language");
  }
  function handleEn() {
    changeLang("en");
    localStorage.setItem("lang", JSON.stringify("en"));
    dispatch({ type: actionTypes.CHANGE_LANGUAGE_TO_ENG, payload: "ENG" });
    toggleDropdown("select-language");
  }
  function handleTr() {
    changeLang("tr");
    localStorage.setItem("lang", JSON.stringify("tr"));
    dispatch({ type: actionTypes.CHANGE_LANGUAGE_TO_TURK, payload: "TURK" });
    toggleDropdown("select-language");
  }

  return (
    <div className="HeaderComponent" style={{ background }}>
      <header>
        <div className="search-logo">
          <a href="/">
            <img className="logotip" width={80} src={imgValue} alt="logo" />
          </a>
          <div className="search-input">
            <input
              ref={titleRef}
              type="text"
              defaultValue={searchValue}
              placeholder={t("search")}
              onKeyUp={(e) => {
                search(e);
              }}
            />
            <i className="fa-solid fa-magnifying-glass"></i>
          </div>
        </div>
        <div className="items">
          <div className="account-dark-mode">
            <Link to="/account" className="current">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill={color}
                className="bi bi-cart4"
                viewBox="0 0 16 16"
              >
                <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5M3.14 5l.5 2H5V5zM6 5v2h2V5zm3 0v2h2V5zm3 0v2h1.36l.5-2zm1.11 3H12v2h.61zM11 8H9v2h2zM8 8H6v2h2zM5 8H3.89l.5 2H5zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0m9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0" />
              </svg>
              <span className="currentCartNumber">{cartNumber}</span>
            </Link>
            <div
              className={`select-language ${
                active == "select-language" ? "active" : "select-language"
              }`}
            >
              <span className="main-language">
                {language}
                <i
                  onClick={() => toggleDropdown("select-language")}
                  className={`fa-solid ${
                    active ? "bi bi-caret-up-fill" : "fa-chevron-down"
                  } mt-1`}
                ></i>
              </span>
              <div className="dropdown">
                <span onClick={handleUz} className="uzb">
                  Uzbek
                </span>
                <span onClick={handleEn} className="eng">
                  English
                </span>
                <span onClick={handleTr} className="turk">
                  Turkish
                </span>
                <span onClick={handleRu} className="rus">
                  Russian
                </span>
              </div>
            </div>
            <div
              className={`dark-mode ${
                color == "white" || isToggled ? "toggled" : ""
              }`}
              onClick={toggleSwitch}
            >
              <div className="toggle-knob">
                {color == "white" || isToggled ? (
                  <i className="fa-solid fa-moon"></i>
                ) : (
                  <i className="fa-solid fa-sun"></i>
                )}
              </div>
            </div>
            <div
              className="account"
              style={
                color == "white"
                  ? { border: "2px solid #fff" }
                  : { border: "2px solid #000" }
              }
            >
              <a href="/account" style={{ color }}>
                <i className="fa-solid fa-user"></i>
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="d-flex justify-content-between align-items-center mb-3 pt-2 border-top ">
        <div className="nav-bar">
          {isBars ? (
            <i
            style={{color}}
              className="fa-solid fa-bars mb-0"
              onClick={() => {
                setBars(false);
              }}
            ></i>
          ) : (
            <i
            style={{color}}
              className="fa-solid fa-remove mb-0"
              onClick={() => {
                setBars(true);
              }}
            ></i>
          )}
        </div>
        <div className="search-responsive-input">
          <input
            type="text"
            className="bg-transparent border-0"
            ref={titleResponsiveRef}
            defaultValue={searchValue}
            placeholder={t("search")}
            onKeyUp={() => {
              searchResponsive();
            }}
          />
          <i className="fa-solid fa-magnifying-glass px-3 py-1"></i>
        </div>
      </section>
      <div className={`navbar ${isBars ? "navbar-header" : ""}`}>
        <li>
          <a
            style={{ color, cursor: "pointer" }}
            className={location?.state == "all" ? "navbar__active--class" : ""}
            onClick={() => {
              navigate("/products", { state: "all" });
            }}
          >
            {t("all_website")}
          </a>
        </li>
        <li>
          <a
            style={{ color, cursor: "pointer" }}
            className={
              location?.state == "portfolio" ? "navbar__active--class" : ""
            }
            onClick={() => {
              navigate("/products", { state: "portfolio" });
            }}
          >
            {t("portfolio_pages")}
          </a>
        </li>
        <li>
          <a
            style={{ color, cursor: "pointer" }}
            className={
              location?.state == "restaurant" ? "navbar__active--class" : ""
            }
            onClick={() => {
              navigate("/products", { state: "restaurant" });
            }}
          >
            {t("restuarant_pages")}
          </a>
        </li>
        <li>
          <a
            style={{ color, cursor: "pointer" }}
            className={
              location?.state == "company" ? "navbar__active--class" : ""
            }
            onClick={() => {
              navigate("/products", { state: "company" });
            }}
          >
            {t("company_pages")}
          </a>
        </li>
        <li>
          <a
            style={{ color, cursor: "pointer" }}
            className={location?.state == "blog" ? "navbar__active--class" : ""}
            onClick={() => {
              navigate("/products", { state: "blog" });
            }}
          >
            {t("blog_pages")}
          </a>
        </li>
        <li>
          <a
            style={{ color, cursor: "pointer" }}
            className={location?.state == "web" ? "navbar__active--class" : ""}
            onClick={() => {
              navigate("/products", { state: "web" });
            }}
          >
            {t("website_elements")}
          </a>
        </li>
      </div>
    </div>
  );
}

export default HeaderComponent;