import MyProfile from "../Components/MyAccount/MyProfile/index";
import MyFavouritres from "../Components/MyAccount/MyFavouritres/index";
import Settings from "../Components/MyAccount/Settings/index";
import { Col, Container, Row } from "reactstrap";
import { useSelector } from "react-redux";
import HeaderForAccount from "../Components/Header/HeaderForAcountPage";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../Components/Footer";
import MyCart from "../Components/MyAccount/MyCart";
//import { Outlet } from "react-router-dom";

function MyAccount() {
  const background = useSelector((state) => state.changeBackground);
  const color = useSelector((state) => state.changeColor);
  const [isBool, setBool] = useState(false);
  const { t } = useTranslation();
  const [Outlet, setOutlet] = useState(<MyCart />);

  const handleClickProfile = () => {
    document.getElementById("settings").classList.remove("active");
    document.getElementById("myFavourites").classList.remove("active");
    document.getElementById("cart").classList.remove("active");
    document.getElementById("myProfile").classList.add("active");
    setOutlet(<MyProfile />);
  };

  const handleClickFavourites = () => {
    document.getElementById("myProfile").classList.remove("active");
    document.getElementById("settings").classList.remove("active");
    document.getElementById("cart").classList.remove("active");
    document.getElementById("myFavourites").classList.add("active");
    setOutlet(<MyFavouritres />);
  };
  const handleClickSetting = () => {
    document.getElementById("myFavourites").classList.remove("active");
    document.getElementById("myProfile").classList.remove("active");
    document.getElementById("cart").classList.remove("active");
    document.getElementById("settings").classList.add("active");
    setOutlet(<Settings />);
  };
  const handleClickCart = () => {
    document.getElementById("myFavourites").classList.remove("active");
    document.getElementById("myProfile").classList.remove("active");
    document.getElementById("settings").classList.remove("active");
    document.getElementById("cart").classList.add("active");
    setOutlet(<MyCart />);
  };
  return (
    <div className="">
      <Container className="shadow  bg-body-tertiary">
        <Row>
          <HeaderForAccount setBool={setBool} />
        </Row>
        <Row>
          {isBool && (
            <Col
              xs="3"
              className="border-end w-100 mb-4"
              style={{ background }}
            >
              <ul className="list-group w-100 d-flex sidebar-list-group list-group-flush">
                <a
                  className="list-group-item list-group-item-action"
                  id="myProfile"
                  style={{ background }}
                  onClick={handleClickProfile}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill={color}
                    className="bi bi-person-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                    <path
                      fillRule="evenodd"
                      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                    />
                  </svg>
                  <span className="">{t("my_profile")}</span>
                </a>
                <a
                  className="list-group-item list-group-item-action "
                  style={{ background }}
                  onClick={handleClickFavourites}
                  id="myFavourites"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill={color}
                    className="bi bi-heart-half"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 2.748v11.047c3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                  </svg>
                  <span className="">{t("my_favouritres")}</span>
                </a>
                <a
                  className="list-group-item list-group-item-action"
                  style={{ background }}
                  onClick={handleClickSetting}
                  id="settings"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill={color}
                    className="bi bi-gear"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0" />
                    <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z" />
                  </svg>
                  <span className="">{t("settings")}</span>
                </a>
              </ul>
            </Col>
          )}
        </Row>
        <Row className="row-wrap">
          <Col
            xs="3"
            className="border-end side-bar pt-5 "
            style={{ background }}
          >
            <ul className="list-group list-group-flush">
              <a
                style={{ background }}
                className="list-group-item list-group-item-action"
                id="myProfile"
                onClick={handleClickProfile}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill={color}
                  className="bi bi-person-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                  <path
                    fillRule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                  />
                </svg>
                <span className="" style={{ color }}>
                  {t("my_profile")}
                </span>
              </a>
              <a
                style={{ background }}
                className="list-group-item list-group-item-action "
                onClick={handleClickFavourites}
                id="myFavourites"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill={color}
                  className="bi bi-heart-half"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 2.748v11.047c3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
                </svg>
                <span className="" style={{ color }}>
                  {t("my_favouritres")}
                </span>
              </a>
              <a
                style={{ background }}
                className="list-group-item list-group-item-action"
                onClick={handleClickSetting}
                id="settings"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill={color}
                  className="bi bi-gear"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0" />
                  <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z" />
                </svg>
                <span className="" style={{ color }}>
                  {t("settings")}
                </span>
              </a>
              <a
                style={{ background }}
                className="list-group-item list-group-item-action"
                onClick={handleClickCart}
                id="cart"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill={color}
                  className="bi bi-cart4"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5M3.14 5l.5 2H5V5zM6 5v2h2V5zm3 0v2h2V5zm3 0v2h1.36l.5-2zm1.11 3H12v2h.61zM11 8H9v2h2zM8 8H6v2h2zM5 8H3.89l.5 2H5zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0m9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0" />
                </svg>
                <span className="" style={{ color }}>
                  {t("cart")}
                </span>
              </a>
            </ul>
          </Col>
          <Col className="outlet-main">
            <main className="shadow main-outlet mb-3">{Outlet}</main>
          </Col>
        </Row>
        <Row className="border-top ">
          <Footer />
        </Row>
      </Container>
    </div>
  );
}

export default MyAccount;
