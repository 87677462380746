import * as actionType from "../../actions/action_types"
import initialStates from "../initialStates";

export default function signUpErrorReducer(state=initialStates.sign_error_message.message,action){
    switch (action.type) {
        case actionType.SIGN_UP_ERROR_MESSAGE:
            return {
                ...state , message : action.payload
            }
         
        default:
            return state ;
    }
}