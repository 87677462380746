import { Form } from "react-bootstrap";
import "./index.css";
import { 
    setDoc, 
    doc, 
    getFirestore, 
    collection, 
    getDocs } from "firebase/firestore";
import app from "../../Backgrounds/firebase";
import { useState } from "react";
import { 
    getStorage, 
    ref, 
    uploadBytes, 
    getDownloadURL } from "firebase/storage";

function UploadData() {

    const db = getFirestore(app);
    const storage = getStorage(app);

    const [product, setProduct] = useState({
        title: "",
        image: null,
        image1: null,
        image2: null,
        description: "",
        price: "",
        oldPrice: ""
    })

    const handleClickChange = (e) => {
        setProduct({
            ...product, [e.target.name]: e.target.value
        })
    }

    const handleClickChangeImg = (e) => {
        setProduct({
            ...product, [e.target.name]: e.target.files[0]
        })
    }


    const handleClick = async (e) => {

        e.preventDefault();

        const coll = collection(db, "ProductsDetail");
        const snapshot = await getDocs(coll);
        const productId = snapshot.size + 1;

        const storageRefs = [
            ref(storage, `/Products/${productId}/1.png`),
            ref(storage, `/Products/${productId}/2.jpg`),
            ref(storage, `/Products/${productId}/3.jpg`)
        ]

        const metadata = {
            contentType: 'image/jpeg',
        };

        await Promise.all(storageRefs.map((storageRef, index) => uploadBytes(storageRef, product[`image${index}`], metadata)));

        const urls = await Promise.all(storageRefs.map(storageRef => getDownloadURL(storageRef)));

        await setDoc(doc(db, "ProductsDetail", `${productId}`), {
            id: productId,
            title: product.title,
            description: product.description,
            price: product.price,
            oldPrice: product.oldPrice,
            image1: urls[0],
            image2: urls[1],
            image: urls[2]
        })
    }

    return (
        <Form className="m-5 p-5 bodyUpload" onSubmit={handleClick} >

            <div class="mb-3 ">
                <label class="form-label">Production Title name</label>
                <input type="text" class="form-control" onChange={handleClickChange} id="title" name="title" placeholder="Production 1" />
            </div>
            <div class="mb-3">
                <label class="form-label">Choose img 1</label>
                <input class="form-control" type="file" onChange={handleClickChangeImg} name="image" id="img1" />
            </div>
            <div class="mb-3">
                <label class="form-label">Choose img 2</label>
                <input class="form-control" type="file" onChange={handleClickChangeImg} name="image1" id="img2" />
            </div>
            <div class="mb-3">
                <label class="form-label">Choose img 3</label>
                <input class="form-control" type="file" onChange={handleClickChangeImg} name="image2" id="img3" />
            </div>
            <div class="row  g-3 align-items-center">

                <div class="col-auto">
                    <input type="text" id="oldPrice" class="form-control" onChange={handleClickChange} name="oldPrice" placeholder="Old Price" />
                </div>
                <div class="col-auto">
                    <input type="text" id="newPrice" class="form-control" onChange={handleClickChange} name="price" placeholder="New Price" />
                </div>
            </div>
            <div class="mb-3 mt-2">
                <label class="form-label">Enter product description</label>
                <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    onChange={handleClickChange}
                    name="description"
                    placeholder="description" />
            </div>

            <button class="btn btn-primary mt-3" type="submit">Submit</button>
        </Form>
    );
}

export default UploadData;