import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import app from "../../../Backgrounds/firebase";
import { toast, ToastContainer } from "react-toastify";
import {
  cartChange,
} from "../../../Redux/actions/actions";
import { useTranslation } from "react-i18next";

function MyCart() {
  const background = useSelector((state) => state.changeBackground);
  const color = useSelector((state) => state.changeColor);
  const [likeData, setLikeData] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const uid = useSelector((state) => state.userIdReducer.uid);
  const [countPrices, setCountPrices] = useState(0);
  const [selectPrices, setSelectPrices] = useState(0);
  const db = getFirestore(app);

  // TAOSTIFY
  const notifyError = () => {
    toast.error("Removed From Cart !", {
      position: "top-center",
      autoClose: 1500,
    });
  };

  async function getLikeBtn() {
    const updatedProducts = [...likeData];
    const coll = collection(db, `/AddToCart/${uid}/${uid}`);
    const snapshot = await getDocs(coll);
    // for operatorumiz fireStoredagi barcha ma'lumotlarni o'qiy olishi uchun ishlash sonini belgilarni soniga ko'ra togi'rladik

    for (let index = 1; index <= snapshot.size; index++) {
      const docRef = doc(
        db,
        `/AddToCart/${uid}/${uid}`,
        `${snapshot.docs[index - 1].id}`
      );
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        updatedProducts.push(docSnap.data());
        setLikeData([...updatedProducts]);
        dispatch(cartChange(updatedProducts?.length));
      } else {
        console.log("No such document!");
      }
    }
  }

  const RemoveToCart = async (product) => {
    const ref = doc(db, `/AddToCart/${uid}/${uid}`, `${product.id}`);
    await updateDoc(ref, {
      like: true,
    });

    if (product?.like) {
      setCountPrices(countPrices - 1);
      setSelectPrices(+selectPrices - Number(product.price));
    }

    notifyError();

    let deletedProduct = [...likeData];
    await deleteDoc(ref);
    let deletedProducts = deletedProduct?.filter((el) => el.id != product.id);
    setLikeData([...deletedProducts]);
    dispatch(cartChange(deletedProducts.length));
    console.log("o'chirildi");
  };

  const updateCheck = async (product, checked) => {
    
    if (checked) {
      setCountPrices(countPrices + 1);
      setSelectPrices(+selectPrices + Number(product.price));
    } else {
      setCountPrices(countPrices - 1);
      setSelectPrices(+selectPrices - Number(product.price));
    }

    const ref = doc(db, `/AddToCart/${uid}/${uid}`, `${product.id}`);

    await updateDoc(ref, {
      like: checked,
    });

  };

  let sum = 0;
  let count = 0;
  let sumLike = 0;
  likeData.forEach((el) => {
    sum = Number(sum) + Number(el.price);
    if (el.like) {
      sumLike = Number(sumLike) + Number(el.price);
      count = Number(count) + 1;
    }
  });
  
  useEffect(() => {
    setCountPrices(count);
    setSelectPrices(sumLike);
  }, [likeData])

  useEffect(() => {
    getLikeBtn();
  }, []);

  return (
    <div
      className="myCart"
      style={
        likeData?.length == 0 ? { background, height: "100vh" } : { background }
      }
    >
      <div className="allProductInCart p-3">
        {/* All cards */}
        {likeData &&
          likeData.map((el, index) => {
            return (
              <div
                key={index}
                className="myCart__cart d-flex align-items-center justify-content-between border shadow rounded p-2"
              >
                <div className="cart--image d-flex align-items-center gap-3 w-75 ">
                  <input
                    onChange={(e) => {updateCheck(el, e.target.checked)}}
                    type="checkbox"
                    defaultChecked={el?.like}
                    width={20}
                  />
                  <img
                    src={el?.image}
                    width={100}
                    height={100}
                    alt="cart image"
                    className="cart--image rounded"
                  />
                  <div style={{ color }} className="cart--text">
                    <h1>{el?.title}</h1>
                    <p className="w-100">
                      {el?.description
                        ? el?.description
                        : "Lorem ipsum, dolor sit amet consectetur adipisicing elit mipsum, dolor sit amet consectetur adipisicing elit.m ipsum,dolor sit amet consectetur adipisicing elit."}
                    </p>
                  </div>
                </div>
                <div style={{ color }} className="cart--action">
                  <h4 style={{ color }} className="text text-orange">
                    $ {el?.price}
                  </h4>
                  <div className="actions">
                    <a
                      href="product/detail"
                      className="text-success border border-2 rounded"
                    >
                      {t("learn_more")} 💚
                    </a>
                    <a
                      onClick={() => {
                        RemoveToCart(el);
                      }}
                      className="text-danger border border-2 rounded"
                    >
                      {t("remove")} ❌
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      {/* General price */}
      <div className="myCart__general w-100 p-2" style={{ color }}>
        <h1 className="text-center"> {t("general_product")}</h1>
        <div
          style={{ color }}
          className="cart w-100 d-flex justify-content-between align-items-center border-bottom border-top border-2"
        >
          <div className="d-flex justify-content-between align-items-center gap-2">
            <h4>{countPrices} {t("tovar_narxi")}</h4>
            <h5>➡️➡️➡️</h5>
          </div>
          <h4 style={{ color }} className="text text-orange">
            $ {selectPrices}
          </h4>
        </div>
        <div
          style={{ color }}
          className="cart w-100 d-flex justify-content-between align-items-center border-bottom border-2 border-top-0"
        >
          <div className="d-flex justify-content-between align-items-center gap-2">
            <h4 className="">{t("general")}</h4>
            <h5>➡️➡️➡️</h5>
          </div>
          <h4 style={{ color }} className="text text-orange">
            $ {sum}
          </h4>
        </div>
        <button className="btn btn-success w-100 mt-2">🛒{t("buy_now")}🛒</button>
      </div>
      <ToastContainer />
    </div>
  );
}

export default MyCart;
