import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './roots/App';
import "reactstrap";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "./Components/Signs/style.css"
import "./Components/Payment/style.css"
import './Components/Styles/Index.css'
import "./Components/Styles/MBranchSytle.css"
import './Languages/i18next'
import { Provider } from 'react-redux';
import {store} from "./Redux/reducers/configureStore"
import { BrowserRouter } from 'react-router-dom';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </>
);



